import React from "react";

const NotFoundPage = () => (
	<div>
		<div
			addClass="has-spacing-medium has-background-white"
			sectionID="services"
		>
			<div className="container content">
				<h1>NOT FOUND</h1>
				<p>You just hit a url that doesn&#39;t exist...</p>
			</div>
		</div>
	</div>
);

export default NotFoundPage;
